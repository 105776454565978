import Head from 'next/head';

const DEFAULT_NAME = 'Content Flywheel';
const DEFAULT_TITLE =
  'Content Flywheel | AI-Powered Content Repurposing Platform';
const DEFAULT_DESC =
  'Maximize content reach with Content Flywheel. Repurpose content into unique, SEO-optimized forms using cutting-edge generative AI technology.';
const DEFAULT_IMAGE =
  'https://cdn.contentflywheel.com/web/images/social-image.webp';
const DEFAULT_URL = 'https://www.contentflywheel.com';

const SEO = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESC,
  image = DEFAULT_IMAGE,
  url = '',
  keywords = [],
}: {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  keywords?: string[];
}) => {
  return (
    <Head>
      <title>{title || DEFAULT_TITLE}</title>
      <meta content={description || DEFAULT_DESC} name="description"></meta>
      <meta content={image || DEFAULT_IMAGE} property="og:image"></meta>
      <meta content={url || DEFAULT_URL} property="og:url"></meta>

      <meta content="website" property="og:type"></meta>
      <meta content={title || DEFAULT_TITLE} property="og:title"></meta>
      <meta content={description || DEFAULT_DESC} property="og:description"></meta>
      <meta content={DEFAULT_NAME} property="og:site_name" />

      {/* Twitter */}
      <meta content={title || DEFAULT_TITLE} name="twitter:title"></meta>
      <meta content={description || DEFAULT_DESC} name="twitter:description"></meta>
      <meta content={image || DEFAULT_IMAGE} name="twitter:image"></meta>
      <meta content="summary_large_image" name="twitter:card"></meta>
      <meta content={DEFAULT_NAME} name="twitter:image:alt"></meta>

      {keywords.length > 0 && <meta content={keywords.join(', ')} name="keywords"></meta>}

      {url?.length && <link rel="canonical" href={url} />}

      {/* Favicon */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#5bbad5"
      />
      <meta name="msapplication-TileColor" content="#2b5797"></meta>
      <meta name="theme-color" content="#ffffff"></meta>
    </Head>
  );
}

export default SEO;
