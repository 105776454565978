'use client';

import { useCallback, useState, useRef } from 'react';
import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import SEO from '~/components/shared/SEO';

export default function Page() {
  const textRef = useRef<any>(null);
  const [formattedJSON, setJSON] = useState<any>({
    status: 'Paste some JSON...',
  });

  const handleTextChange = useCallback(() => {
    try {
      setJSON(JSON.parse(textRef.current.value));
    } catch (e) {
      setJSON({ status: 'Invalid JSON' });
    }
  }, []);

  const copyJson = () => {
    navigator.clipboard.writeText(JSON.stringify(formattedJSON, undefined, 2));
  };

  return (
    <>
      <SEO
        title={`JSON Parser | Content Flywheel`}
        description={`Simplify JSON parsing. Try our fast, user-friendly JSON parser tool. Debug, analyze, and visualize JSON data in seconds.`}
        url={`https://www.contentflywheel.com/tools/json-parser`}
      />
      <div className="flex justify-center overflow-x-hidden">
        <div className="flex flex-col relative items-center w-full max-w-screen-3xl min-h-[500px] p-4 md:p-10">
          <h1 className="font-heading text-4xl font-bold text-center mb-4">JSON Parser</h1>
          <div
            className="btn btn-sm btn-info text-white cursor-pointer mb-4 absolute top-5 md:top-12 right-5 md:right-12"
            onClick={copyJson}
          >
            Copy
          </div>
          <div className="hidden md:block w-2/3 mt-4 mb-8 text-muted-foreground">
            Ditch the frustration of parsing JSON manually. Our user-friendly
            JSON parser transforms your raw JSON data into an easily
            understandable format. Perfect for developers and anyone who works
            with JSON. Boost your productivity with our intuitive JSON parser.
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row justify-center md:justify-around w-full">
              <textarea
                ref={textRef}
                className="textarea textarea-bordered dark:bg-[#08131c] bg-transparent mb-4 w-full md:w-1/2 leading-6 min-h-[500px] placeholder:italic p-4 mr-2"
                placeholder="Paste some JSON..."
                onChange={handleTextChange}
              />
              <div
                className="textarea textarea-bordered dark:bg-[#08131c] mb-4 bg-transparent w-full md:w-1/2 min-h-[500px] placeholder:italic text-base p-4 mr-2">
                <JsonView
                  data={formattedJSON}
                  shouldExpandNode={allExpanded}
                  style={{
                    ...darkStyles,
                    container: 'bg-none max-w-1/2 text-wrap break-all',
                    basicChildStyle: 'pl-6',
                    stringValue: 'text-info',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
